import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import About from './About';
import { AppBar, Button, Toolbar } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 2 }}>
      {'Copyright © '}
      <Link color="inherit" href="https://swipenot.com/">
        Swipenot
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function App() {
  return (
    <Box sx={{
      // background: "linear-gradient(179deg, #FEEFE7 0.22%, #FFE3D3 80.82%, #F8C8A6 99.32%)",
      mt: 8
    }}>
      <Container maxWidth="md">
        <AppBar color="primary" sx={{
          justifyContent: "space-between",
          flexDirection: "row",
        }}>
          <Box></Box>
          <Toolbar sx={{
            maxWidth: "md",
            flexGrow: 1,
          }}>
            <Typography variant='h5' sx={{ color: "white" }} flexGrow={1} fontWeight={500}>Swipenot</Typography>
            <Box>
              <Button href="https://swipenot.com" target='_blank' endIcon={<OpenInNewIcon />} sx={{
                color: 'text.primary'
              }}>Try it yourself</Button>
            </Box>
          </Toolbar>
          <Box></Box>
        </AppBar>
        <Box sx={{ mx: 2 }}>
          <About />
          <Copyright />
        </Box>
      </Container >
    </Box >
  );
}
