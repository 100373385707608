import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#f08a41',
      light: "#f8c8a6",
    },
    secondary: {
      main: '#f8c8a6',
    },

    error: {
      main: red.A400,
    },
  },
});

export default theme;
