import * as React from 'react';
import Link from '@mui/material/Link';
import { Box, Button, Card, Typography } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

function Paragraph(props: { children: React.ReactNode }) {
  return (
    <Typography sx={{ mb: 2 }} color="text.secondary">
      {props.children}
    </Typography>
  )
}

function Section1() {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography sx={{ pt: 3, pb: 3 }} variant='h5' color="text.primary" fontWeight={500}>
        Let’s Fuel the Future of Retail
      </Typography>

      <Paragraph>
        Calling all retailers: Are you ready to skyrocket your sales and reach more customers?
      </Paragraph>

      <Paragraph>
        We're looking for innovative, top retailers eager to harness the power of our app's
        seamless shopping experience.
      </Paragraph>

      <Card sx={{ mb: 2, p: 2 }} raised={true}>
        <Typography color="text.secondary">
          <FormatQuoteIcon sx={{ transform: "scaleX(-1)" }} fontSize='small' />
          Our tests show that users who visit our app have a 1 in 3 chance of finding and
          purchasing a product they wouldn't have found through traditional digital marketing.
          So let us help customers discover and buy YOUR products.
          <FormatQuoteIcon fontSize='small' />
        </Typography>
      </Card>

      <Paragraph>
        All we ask for is your product feed. You know the <Link
          href='https://support.google.com/merchants/answer/7439882?hl=en&ref_topic=7438667&sjid=586594500924736626-NA'>
          same product feed you share with Google Shopping
        </Link>?  It’s that simple.
      </Paragraph>

      <Paragraph>
        No feed access? No problem. With permission, we’ll happily
        crawl your site.
      </Paragraph>

      <Paragraph>
        To get started now, submit the form below or email us at <Link href='mailto:hello@swipenot.com'>hello@swipenot.com</Link>.
        And here's the cherry on top: we won't charge any fees, and we'll send quality
        traffic your way for FREE. 🎉
      </Paragraph>

      <Paragraph>
        Remember, if you ever change your mind, simply shoot us an email and we'll
        remove your products. No strings attached.
      </Paragraph>
      <Button variant='contained' sx={{ color: "white" }} href='https://share.hsforms.com/1sHqgvnMKSjqghjOWwhruDwnmaz8'>
        Let’s connect
      </Button>
    </Box >
  )
}

function Section2() {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography sx={{ pt: 3, pb: 3 }} variant='h5' color="text.primary">
        Our Small Team with Huge Ambitions
      </Typography>

      <Paragraph>
        Greetings from the Bay Area!
      </Paragraph>

      <Paragraph>
        We're a dedicated trio of engineers on a mission to change the way
        people shop for clothes, one swipe at a time.
      </Paragraph>

      <Paragraph>
        Even though our website is ready to launch, we plan to take Swipenot much
        farther than that. We’re already in the works in developing a mobile app,
        securing venture funding, and generating revenue through affiliate marketing.
      </Paragraph>

      <Paragraph>
        It would be an honor to have a retailer such as yourself join us as one of the
        earliest retailers on our platform.
      </Paragraph>

      <Paragraph>
        Ready to swipe right on Swipenot? <Link
          href='https://share.hsforms.com/1sHqgvnMKSjqghjOWwhruDwnmaz8'>Complete our form
        </Link> or email us through the link below.
      </Paragraph>

      <Button variant='contained' sx={{ color: "white" }} href='mailto:hello@swipenot.com'>Drop us a line</Button>
    </Box>
  )
}

export default function About() {
  return (
    <React.Fragment>
      <Box>
        <Typography sx={{ pt: 3, pb: 3 }} variant='h5' color="text.primary" fontWeight={500}>
          🎉 Welcome to Swipenot, your personal shopping aggregator.
        </Typography>
        <Paragraph>
          Today’s consumers don’t always know what they want. In fact, they want you to tell them.
        </Paragraph>
        <Paragraph>
          They don’t want to click, scroll, and research. Instead, they want a truly frictionless
          shopping experience that matches the sophistication of our modern world.
        </Paragraph>
        <Paragraph>
          So say goodbye to the old - fashioned way of shopping, and give a warm welcome to Swipenot.
        </Paragraph>
        <Paragraph>
          Swipenot eliminates the burden of browsing. Simply swipe up or down for a curated
          selection of clothing, tailored to each consumer’s specific taste. We learn what
          users like and then present them with products from diverse retailers—often causing
          first-time discoveries.
        </Paragraph>
        <Paragraph>
          But that’s not all we do. Swipenot unifies the best product from global top retailers
          in one place with a 1 - step path to purchase on the retailer’s site.
        </Paragraph>
        <Paragraph>
          What does that mean for you? Big bumps in traffic.
        </Paragraph >
      </Box>

      <Section1></Section1>
      <Section2></Section2>
    </React.Fragment>
  );
}
